import React, { useContext } from 'react';
import styles from './MainProgram.module.scss';
import { LanguageContext } from '../Context/LanguageContext';  // 引入LanguageContext

const MainProgram1 = () => {
  const { language } = useContext(LanguageContext);  // 获取当前语言状态

  return (
    <div className={styles.mainProgram}>
      <h2 className={styles.title}>{language === 'zh' ? '我们的主要项目 1' : 'Our Main Programs 1'}</h2>

      <div className={styles.contentWrapper}>
        <div className={styles.programSection}>
          <div className={styles.textSection}>
            <h3>{language === 'zh' ? '按摩师' : 'Massage Therapy'}</h3>
            <h4>2000 {language === 'zh' ? '小时' : 'Hours'} /50 {language === 'zh' ? '周' : 'Weeks'}</h4>
            <p>{language === 'zh' ? '按摩疗法课程为您提供成为健康团队宝贵成员所需的深入学术准备和实践经验。' : 'Massage Therapy program provides in-depth academic preparation and hands-on practical experience to become a valued member of a health care team.'}</p>
            <p className={styles.approved}>{language === 'zh' ? '根据《安大略省职业学院法案，2005》批准的职业培训项目' : 'Approved as a vocational program under the Ontario Career Colleges Act, 2005'}</p>
          </div>
          <img src='/assets/images/main_program_2_1.svg' alt='Massage Therapy' className={styles.programImage}/>
        </div>

        <div className={styles.programSection}>
          <img src='/assets/images/main_program_2_2.svg' alt='Career Opportunities' className={styles.programImage}/>
          <div className={styles.textSection}>
            <h3>{language === 'zh' ? '就业机会' : 'Career Opportunities'}</h3>
            <p>{language === 'zh' ? '实践与理论相结合的课程 + 学生临床实习：' : 'Practical & Theoretical hybrid classes+ student clinic: '}</p>
            <ul>
              <li>{language === 'zh' ? '提供深入的学术和实践经验，帮助通过CMTO考试' : 'Offers in-depth academic and practical experience to CMTO exam'}</li>
              <li>{language === 'zh' ? '为加入医疗团队做好准备' : 'Prepares you to join a health care team'}</li>
              <li>{language === 'zh' ? '适合热衷于身心健康的人' : 'Ideal for those passionate about well-being'}</li>
              <li>{language === 'zh' ? '启动你的按摩治疗职业生涯' : 'Launches your career in massage therapy'}</li>
              <li>{language === 'zh' ? '为毕业生在水疗、度假村或独立工作做好准备' : 'Prepares graduates for spa, resort, or independent work '}</li>
              <li>{language === 'zh' ? '毕业生可在健康与保健中心、水疗、度假村、医疗水疗、健身俱乐部、游轮和家庭护理等场所工作' : 'Graduates can work in settings like health & wellness centers, spas, resorts, medical spas, fitness clubs, cruise ships, and home care'}</li>
            </ul>
          </div>
        </div>

        <div className={styles.programSection}>
          <div className={styles.textSection}>
            <h3>{language === 'zh' ? '财政援助' : 'Financial Assistance'}</h3>
            <ul>
              <li>{language === 'zh' ? '加拿大学生可能有资格获得财政援助' : 'Canadian students may be eligible for financial assistance'}</li>
              <li>{language === 'zh' ? '可能的资助项目包括：安省更好工作计划（Better Jobs Ontario）、社会救助（Social Assistance）和安省残障支援计划（ODSP）' : 'Possible aid includes Better Jobs Ontario, Social Assistance, and The Ontario Disability Support Program (ODSP)'}</li>
              <li>{language === 'zh' ? '可能有快速银行贷款可供选择' : 'Quick bank loans may be available'}</li>
              <li>{language === 'zh' ? '学院提供延期付款计划' : 'Deferred payment plans are offered by the college'}</li>
              <li>{language === 'zh' ? '安省技工学院（OCOT）每年提供20%到40%的奖学金' : 'OCOT offers 20% to 40% scholarships every year'}</li>
            </ul>
          </div>
          <img src='/assets/images/main_program_2_3.svg' alt='Financial Assistance' className={styles.programImage}/>
        </div>
      </div>
    </div>
  );
};

export default MainProgram1;
