import styles from './Map.module.scss';
import { useContext } from 'react'; // 引入 useContext
import { LanguageContext } from '../Context/LanguageContext'; // 引入 LanguageContext

const Map = () => {
    const { language } = useContext(LanguageContext); // 获取当前语言状态

    const mobileAddress = "3470 Pharmacy Ave, Toronto, Ontario"; // 移动端地址
    const fullAddress = "3470 Pharmacy Ave, Toronto, Ontario, Canada M1W 2S7"; // PC端地址

    return (
        <div className={styles.mapWrapper}>
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8673.275196028238!2d-79.34235764114261!3d43.81021719987719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d172d1eaaaab%3A0x6b56ddcda4410018!2sOntario%20College%20of%20Technology!5e0!3m2!1szh-CN!2sca!4v1725647456071!5m2!1szh-CN!2sca" 
                width="100%" 
                height="480" 
                style={{ border: 0 }} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                title='map' 
                draggable="false"
            >
            </iframe>
            <div className={styles.mapText}>
                <div className={styles.infoBlock}>
                    <img src="/assets/images/map_loc.svg" alt="Location" />
                    <div className={styles.textBlock}>
                        <h3>OCOT</h3>
                        <p>{window.innerWidth < 768 ? mobileAddress : fullAddress}</p> {/* 根据屏幕宽度显示地址 */}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <img src="/assets/images/map_business_time.svg" alt="Hours" />
                    <div className={styles.textBlock}>
                        <div className={styles.hoursWrapper}>
                            <div>
                                <h3>{language === 'zh' ? '工作日' : 'Weekday'}</h3>
                                <p>9:00 - 17:00</p>
                            </div>
                            <div>
                                <h3>{language === 'zh' ? '周末' : 'Weekend'}</h3>
                                <p>10:00 - 17:00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Map;
