import './App.css';
import Header from './components/Header';
import Banner from './components/Banner';
import Feature from './components/Feature';
import WhyChoose from './components/WhyChoose';
import Footer from './components/Footer';
import Map from './components/Map';
import Float from './components/Float';
import HeroSection from './components/HeroSection';
import MiniProgram from './components/MiniProgram';
import MainProgram1 from './components/MainProgram1';
import MainProgram2 from './components/MainProgram2';
import MainProgram3 from './components/MainProgram3';
import StudentsReviews from './components/StudentsReviews';
import { useWindowScrollPositions } from './hooks/useWindowScrollPositions';
import { useIsMobile } from './hooks/useIsMobile';
import { LanguageProvider } from './components/Context/LanguageContext'; 

function App() {
  const { scrollY } = useWindowScrollPositions();

  return (
    <LanguageProvider>
      <div className="App">
        <Header />
        <HeroSection />
        <MainProgram1 />
        <MainProgram2 />
        <MainProgram3 />
        <MiniProgram />
        <Banner />
        <Feature />
        <WhyChoose />
        <StudentsReviews />
        <Map />
        <Footer />
        
        {/* Conditional rendering for Float based on scroll and device */}
        {!useIsMobile() && scrollY > 100 ? <Float /> : null}
        {useIsMobile() && scrollY > 560 ? <Float /> : null}
      </div>
    </LanguageProvider>
  );
}

export default App;
