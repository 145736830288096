import React, { useContext } from 'react';  // 引入 useContext
import { LanguageContext } from '../Context/LanguageContext';  // 引入LanguageContext
import styles from './WhyChoose.module.scss';

const data = {
  en: [
    {
      id: 1,
      title: "Government-Approved Educational Institution",
      description: "Ontario College of Technology (OCOT) is registered as a 'Private Career College' under the Ontario Private Career Colleges Act, 2005, and is a Designated Learning Institution (DLI) approved by the Government of Canada's Immigration, Refugees and Citizenship Canada (IRCC) to admit international students.",
    },
    {
      id: 2,
      title: "Financial Aid",
      description: "Some government programs provide financial assistance to help cover tuition, living expenses, books, childcare, and more.",
    },
    {
      id: 3,
      title: "High Employment Rate",
      description: "OCOT’s career-focused education programs are designed with the job market in mind, leading to high employment rates among graduates in their respective fields.",
    },
    {
      id: 4,
      title: "Pass Guarantee Policy",
      description: "For courses that prepare students for exams and industry certifications, we offer a 'Pass Guarantee Policy' to provide risk-free training.",
    },
    {
      id: 5,
      title: "Free Employment Services",
      description: "Our professional career advisors offer free employment services to graduates, including resume writing, interview coaching, and employer referrals.",
    },
    {
      id: 6,
      title: "Reputable Faculty",
      description: "OCOT hires top-notch instructors with excellent education and industry experience because we believe that only the best teachers can provide the best education and training.",
    },
    {
      id: 7,
      title: "Industry-Leading Curriculum Design",
      description: "Our courses are designed by industry leaders who integrate the latest research and trends into the classroom, equipping students with professional knowledge for success.",
    },
    {
      id: 8,
      title: "Supportive Learning Environment",
      description: "We focus on providing a positive growth environment for students, supported by dedicated faculty and staff committed to helping students succeed.",
    }
  ],
  zh: [
    {
      id: 1,
      title: "政府承认的合格教育机构",
      description: "安省理工学院根据安省《职业学院法》2005年注册的“注册的职业学院”，也是加拿大联邦政府公民和移民部（CIC）批准的招收国际学生的指定学习学院（Designated Learning Institutions）。",
    },
    {
      id: 2,
      title: "财政资助",
      description: "一些政府项目可以为学生提供财政援助来支付学费、生活费、书籍、日托等。",
    },
    {
      id: 3,
      title: "高就业率",
      description: "OCOT的职业教育课程以就业市场为导向，大量毕业生都在相关行业找到了工作。",
    },
    {
      id: 4,
      title: "保证通过政策",
      description: "对于准备通过考试获取行业证书的课程，我们设置了“保证通过政策”来提供无风险的培训。",
    },
    {
      id: 5,
      title: "免费就业服务",
      description: "我们的专业就业顾问为毕业生提供免费就业服务，包括简历撰写、面试技巧培训、雇主推荐等。",
    },
    {
      id: 6,
      title: "享有盛誉的教师",
      description: "OCOT只聘用最好的教育和行业经验的教师，因为我们相信只有最好的教师才能提供最好的教育和培训。",
    },
    {
      id: 7,
      title: "行业领先的课程设计",
      description: "我们的课程由行业领袖设计，他们将行业的最新研究和趋势整合到课堂中，用专业知识帮助学生走向成功。",
    },
    {
      id: 8,
      title: "支持性学习环境",
      description: "我们的重点是为学生提供一个良好的成长环境，并且致力于帮助学生的教职员工和工友。",
    }
  ]
};

const WhyChoose = () => {
  const { language } = useContext(LanguageContext);  // 获取语言状态

  return (
    <div className={styles.whyChooseContainer}>
      <h2 className={styles.heading}>
        {language === 'zh' ? '为什么选择OCOT？' : 'Why Choose OCOT?'}
      </h2>
      <div className={styles.gridContainer}>
        {data[language].map((item) => (
          <div key={item.id} className={styles.card}>
            <div className={styles.circle}>{item.id}</div>
            <h3 className={styles.title}>{item.title}</h3>
            <p className={styles.description}>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChoose;
