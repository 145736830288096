import React, { useContext } from 'react';  // 引入 useContext
import { LanguageContext } from '../Context/LanguageContext';  // 引入 LanguageContext
import styles from './StudentsReviews.module.scss';

const reviews = {
  en: [
    {
      name: 'Linda Maryrose',
      image: '/assets/images/student_1.svg',
      content: 'I was lucky to have found the OCOT SAS fundamentals Program early this year, and I continued my training of SAS Advanced Co-op Projects Program to pursue my career. The OCOT’s curriculum was well designed and instructors were great. Each project was taught by a senior specialist with expertise from that industry. The practical and hands-on training enabled me to start a new career. The instructors and staff were caring and concerned about my progress, as well as my well-being. Studying at OCOT was an experience I will remember.',
    },
    {
      name: 'Elaine Zhang',
      image: '/assets/images/student_2.svg',
      content: 'I am grateful to OCOT, both the teacher and the administration. They’ve made efforts ensuring maximum number of students achieve professional excellence by getting certification in their field of expertise. I was completely confident to take the CQE exams at the end of the course. A great learning experience from the teacher and guarantee to succeed from administration boost the confidence needed.',
    },
    {
      name: 'Usman Shah',
      image: '/assets/images/student_3.svg',
      content: 'I enrolled in the PSW program at Ontario College of Technology and couldn’t be happier with my decision. The faculty’s dedication to student success is commendable. The blend of theoretical learning and practical training set a strong foundation for my career.',
    },
    {
      name: 'Dongsong Chen',
      image: '/assets/images/student_4.svg',
      content: 'The Professional Culinary Arts Program at Ontario College of Technology has truly transformed my passion for cooking into a refined skill set. The hands-on approach, expert instructors, and industry-relevant curriculum have provided an invaluable foundation for my culinary journey, making it an exceptional choice for aspiring chefs.',
    },
    {
      name: 'Danielle',
      image: '/assets/images/student_5.svg',
      content: 'My experience in the PSW program at Ontario Tech University has been fantastic. The expertise and guidance of the instructors have helped me grow both professionally and personally.',
    },
    {
      name: 'Rebekah',
      image: '/assets/images/student_6.svg',
      content: 'I signed up for the PSW course at Ontario Tech University, and I am very satisfied with my decision. The dedication of the teachers to the success of the students is commendable. The combination of theoretical learning and practical training has laid a solid foundation for my career.',
    },
    {
      name: 'Annie',
      image: '/assets/images/student_7.svg',
      content: 'I entered the massage therapy program with minimal knowledge. Thanks to the enthusiastic instructors at Ontario Tech University, I am now full of confidence.',
    },
    {
      name: 'Angel Lee',
      image: '/assets/images/student_8.svg',
      content: 'I am very pleased to share my wonderful experience in the Massage Therapy program at Ontario Tech University. The expert instructors, comprehensive curriculum, excellent university support, good facilities, and focus on practical skills truly shaped my learning journey and prepared me for success in the field of massage therapy.',
    },
    {
      name: 'Shrey Bhavsar',
      image: '/assets/images/student_9.svg',
      content: 'When I was studying for the Certified Quality Engineer (CQE) course at OCOT, I was a new immigrant to Canada. I am very grateful for all the knowledge I gained during the course, which helped me easily pass the CQE exam and obtain the ASQ certification. This ASQ certification allowed me to quickly find a quality engineer position at a renowned company. The concepts and skills I learned in the course have been extremely helpful in my job. The instructors at OCOT are knowledgeable, experienced, and very supportive in exam preparation. I strongly recommend OCOT for anyone looking to pursue a career in the field of quality engineering.',
    }
  ],
  zh: [
    {
      name: 'Linda Maryrose',
      image: '/assets/images/student_1.svg',
      content: '我很幸运在今年年初找到了OCOT的SAS基础课程，并继续参加了SAS高级合作项目培训，以追求我的职业生涯。OCOT的课程设计得非常好，导师们也很优秀。每个项目都是由具有该行业专业知识的高级专家授课。实践和动手培训让我有足够的能力开始新的职业生涯。导师和工作人员关心我的进展，也关心我的身心健康。在OCOT的学习经历将让我难以忘怀。',
    },
    {
      name: 'Elaine Zhang',
      image: '/assets/images/student_2.svg',
      content: '我非常感谢OCOT的老师和管理团队。他们付出了努力，确保尽可能多的学生通过获得专业领域的认证来达到职业卓越。我在课程结束时完全有信心参加CQE考试。老师的教学提供了很棒的学习体验，而管理团队的成功保证更是增加了所需的信心。',
    },
    {
      name: 'Usman Shah',
      image: '/assets/images/student_3.svg',
      content: '我报名参加了安大略科技学院的个人支持工作者(PSW)课程，对自己的决定感到非常高兴。学院教职员工对学生成功的奉献精神值得称赞。理论学习与实践培训的结合，为我的职业生涯奠定了坚实的基础。',
    },
    {
      name: 'Dongsong Chen',
      image: '/assets/images/student_4.svg',
      content: '安大略科技学院的专业烹饪艺术课程真正将我对烹饪的热情转化为精准的技能。其实践性教学、专家级讲师以及与行业相关的课程为我的烹饪之旅提供了无价的基础，是有志成为厨师的人的绝佳选择。',
    },
    {
      name: 'Danielle',
      image: '/assets/images/student_5.svg',
      content: '我在安大略理工大学的个人支持工作者(PSW)项目的经历非常棒。导师们的专业知识和指导帮助我在职业上和个人方面都取得了成长。',
    },
    {
      name: 'Rebekah',
      image: '/assets/images/student_6.svg',
      content: '我报名了安大略理工大学的护理助理课程，对这个决定非常满意。老师们对学生成功的奉献精神值得称赞。理论学习与实践培训相结合，为我的职业生涯奠定了坚实的基础。',
    },
    {
      name: 'Annie',
      image: '/assets/images/student_7.svg',
      content: '我在进入按摩疗法课程时几乎没有什么知识。多亏了安大略理工大学的热情导师们，我现在充满了信心。',
    },
    {
      name: 'Angel Lee',
      image: '/assets/images/student_8.svg',
      content: '我很高兴分享我在安大略理工大学按摩疗法课程中的美好经历。专业的导师、全面的课程、优秀的大学支持、良好的设施以及对实践技能的重视，真正塑造了我的学习之旅，并为我在按摩疗法领域的成功做好了准备。',
    },
    {
      name: 'Shrey Bhavsar',
      image: '/assets/images/student_9.svg',
      content: '当我在OCOT学习认证质量工程师（CQE）课程时，我是新移民到加拿大。非常感激在课程中获得的所有知识，这些知识帮助我顺利通过了CQE考试，并获得了ASQ认证。这个ASQ认证让我迅速在一家知名公司找到质量工程师职位。我在课程中学到的概念和技能在工作中非常有用。OCOT的讲师知识渊博，经验丰富，考试准备的支持非常强大。我强烈推荐OCOT给任何想要从事质量工程领域职业的人。',
    }
  ]
};

const StudentsReviews = () => {
  const { language } = useContext(LanguageContext);  // 获取当前语言状态

  return (
    <div className={styles.reviewsContainer}>
      <h2 className={styles.title}>
        {language === 'zh' ? '学生怎么说？' : 'What Students Say?'}
      </h2>
      <div className={styles.reviewsGrid}>
        {reviews[language].map((review, index) => (
          <div key={index} className={styles.reviewCard}>
            <img src={review.image} alt={review.name} className={styles.reviewImage} />
            <h3 className={styles.reviewName}>{review.name}</h3>
            <p className={styles.reviewContent}>{review.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentsReviews;
