import React, { useContext } from 'react';  // 引入 useContext
import { LanguageContext } from '../Context/LanguageContext';  // 引入LanguageContext
import styles from './MiniProgram.module.scss';

const programs = {
  en: [
    {
      title: "Computer Network Technology",
      duration: "Program Duration: 40 weeks",
      hours: "Total Hours: 800 hours",
      description: "Online classes",
      classes:["Establish, operate, and maintain LANs, WANs, and mainframe networks",
        "Set up and manage Internet and Intranet websites, hardware, and software",
        "Monitor and optimize network performance and connectivity",
        "Prepares students for A+, MCSE, and CCNA certifications",
        "Employed in IT units across private and public sectors",
        "Supervisors of computer network technicians are part of this group",
        "Example job titles include LAN administrator, Web technician, network administrator, and system administrator; the course covers PC hardware, operating systems, network fundamentals, TCP/IP, Microsoft Windows networking, Unix administration, and Cisco routing"
      ],
      assistance:["Canadian students may be eligible for financial assistance",
        "Possible aid includes Better Jobs Ontario, Social Assistance, and The Ontario Disability Support Program (ODSP)",
        "Quick bank loans may be available",
        "Deferred payment plans are offered by the college",
        "OCOT offers 20% to 40% scholarships every year"
      ],
      image: "/assets/images/mini_program_9.svg"
    },
    {
      title: "Data Analytics and Business Intelligence",
      duration: "Program Duration: 50 weeks",
      hours: "Total Hours: 650 hours",
      description: "Online classes",
      classes:["Learn to install, configure, and manage Microsoft SQL Server, including troubleshooting",
        "Gain skills in programming databases and using Transact-SQL to implement and manage database objects",
        "Cover essential concepts in probability models, distributions, and statistical methods with practical applications",
        "Develop and debug SAS programs for data analysis, focusing on effective manipulation techniques",
        "Understand the planning, design, and maintenance of data warehouses for decision support",
        "Explore methods for extracting knowledge from data, including decision trees, neural networks, and text mining",
        "Learn best practices for designing and implementing Business Intelligence solutions"
      ],
      assistance:["Canadian students may be eligible for financial assistance",
        "Possible aid includes Better Jobs Ontario, Social Assistance, and The Ontario Disability Support Program (ODSP)",
        "Quick bank loans may be available",
        "Deferred payment plans are offered by the college",
        "OCOT offers 20% to 40% scholarships every year"
      ],
      image: "/assets/images/mini_program_8.svg"
    },
    {
      title: "Medical Esthetics",
      duration: "Program Duration: 1 year",
      hours: "Total Hours: 1450 hours",
      details: {
        theory: "Theory: 750 hours",
        practice: "660 hours",
        internship: "40 hours"
      },
      image: "/assets/images/mini_program_3.svg"
    },
    {
      title: "Esthetics",
      duration: "",
      hours: "Total Hours: 1040 hours",
      description: "Practical & Theoretical classes + internship",
      classes:["Equip individuals for careers in Esthetics",
        "Learn skin care, human anatomy, nutrition, and the importance of bacteriology and decontamination",
        "Gain skills in nail technology, skin care, manicures & pedicures, make-up artistry, body treatments, and facials",
        "Cover business management skills for salons and spas",
        "Basic computer skills are included"
      ],
      assistance:["Canadian students may be eligible for financial assistance",
        "Possible aid includes Better Jobs Ontario, Social Assistance, and The Ontario Disability Support Program (ODSP)",
        "Quick bank loans may be available",
        "Deferred payment plans are offered by the college",
        "OCOT offers 20% to 40% scholarships every year"
      ],
      image: "/assets/images/mini_program_2.svg"
    },
    {
      title: "Early Childhood Assistant",
      duration: "",
      hours: "Total Hours: 1025 hours",
      description: "online class + internship",
      classes:["Equips childcare workers for Early Childhood Assistant (ECA) roles",
        "Popular program",
        "Easy employment",
        "High starting salary",
        "Job stability",
        "No exams required",
        "Valuable ECA practicum experience"
      ],
      assistance:["Canadian students may be eligible for financial assistance",
        "Possible aid includes Better Jobs Ontario, Social Assistance, and The Ontario Disability Support Program (ODSP)",
        "Quick bank loans may be available",
        "Deferred payment plans are offered by the college",
        "OCOT offers 20% to 40% scholarships every year"
      ],
      image: "/assets/images/mini_program_6.svg"
    },
    {
      title: "Acupuncture",
      duration: "",
      hours: "Total Hours: 1900 hours",
      description: "Practical & Theoretical hybrid classes+inernship",
      classes:["Comprehensive Diploma in Acupuncture program",
        "Combines theoretical knowledge and practical skills",
        "Rooted in Traditional Chinese Medicine",
        "Prepares students to become registered acupuncturists",
        "Graduates eligible for Pan-Canadian (PAN-CAN) exams"
      ],
      assistance:["Canadian students may be eligible for financial assistance",
        "Possible aid includes Better Jobs Ontario, Social Assistance, and The Ontario Disability Support Program (ODSP)",
        "Quick bank loans may be available",
        "Deferred payment plans are offered by the college",
        "OCOT offers 20% to 40% scholarships every year"
      ],
      image: "/assets/images/mini_program_7.svg"
    },
    {
      title: "Quality Assurance",
      duration: "",
      hours: "Total Hours: 800 hours",
      description: "Practical & Theoretical classes",
      classes:["Must-have diploma for quality professionals",
        "Covers essential knowledge and skills for the field",
        "Prepares students for ASQ certifications (CQE, CQA, CSSBB, etc.)"
      ],
      assistance:["Canadian students may be eligible for financial assistance",
        "Possible aid includes Better Jobs Ontario, Social Assistance, and The Ontario Disability Support Program (ODSP)",
        "Quick bank loans may be available",
        "Deferred payment plans are offered by the college",
        "OCOT offers 20% to 40% scholarships every year"
      ],
      image: "/assets/images/mini_program_1.svg"
    },
    {
      title: "Business Management",
      duration: "Program Duration: 1 year",
      hours: "Total Hours: 960 hours",
      description: "Theoretical course + practical project",
      image: "/assets/images/mini_program_4.svg"
    },
    {
      title: "IBM - Finance",
      duration: "Program Duration: 1 year",
      hours: "Total Hours: 960 hours",
      description: "Theoretical course + practical project",
      image: "/assets/images/mini_program_5.svg"
    }
  ],
  zh: [
    {
      title: "计算机网络技术",
      duration: "持续时间: 40 周",
      hours: "总时数: 800 小时",
      description: "在线课程",
      classes:["建立、操作和维护局域网（LAN）、广域网（WAN）和大型机网络",
        "设置和管理互联网和内联网网站、硬件和软件",
        "监控和优化网络性能和连接性",
        "为A+、MCSE和CCNA认证做好准备",
        "在私营和公共部门的IT部门工作",
        "计算机网络技术员的主管属于此类别",
        "示例职位包括局域网管理员、网页技术员、网络管理员和系统管理员；课程涵盖PC硬件、操作系统、网络基础、TCP/IP、Microsoft Windows网络、Unix管理和Cisco路由"
      ],
      assistance:["加拿大学生可能有资格获得财政援助",
        "可能的援助包括Better Jobs Ontario、社会援助和安大略省残疾支持计划（ODSP）",
        "可能有快速银行贷款",
        "学院提供延期付款计划",
        "OCOT每年提供20%至40%的奖学金"
      ],
      image: "/assets/images/mini_program_9.svg"
    },
    {
      title: "数据分析和商业智能",
      duration: "持续时间: 50 周",
      hours: "总时数: 650 小时",
      description: "在线课程",
      classes:["学习安装、配置和管理Microsoft SQL Server，包括故障排除",
        "获得编程数据库和使用Transact-SQL来实现和管理数据库对象的技能",
        "涵盖概率模型、分布和统计方法的基本概念，并进行实际应用",
        "开发和调试SAS程序以进行数据分析，重点关注有效的数据操作技术",
        "了解数据仓库的规划、设计和维护，以支持决策",
        "探索从数据中提取知识的方法，包括决策树、神经网络和文本挖掘",
        "学习设计和实施商业智能解决方案的最佳实践"
      ],
      assistance:["加拿大学生可能有资格获得财政援助",
        "可能的援助包括Better Jobs Ontario、社会援助和安大略省残疾支持计划（ODSP）",
        "可能有快速银行贷款",
        "学院提供延期付款计划",
        "OCOT每年提供20%至40%的奖学金"
      ],
      image: "/assets/images/mini_program_8.svg"
    },
    {
      title: "医美",
      duration: "学制: 1年",
      hours: "总时数: 1450 小时",
      details: {
        theory: "理论: 750 小时",
        practice: "实践: 660 小时",
        internship: "实习: 40 小时"
      },
      image: "/assets/images/mini_program_3.svg"
    },
    {
      title: "美容",
      duration: "",
      hours: "总时数: 1040 小时",
      description: "实践与理论课程+实习",
      classes:["为美容职业生涯做好准备",
        "学习皮肤护理、人类解剖学、营养以及细菌学和消毒的重要性",
        "获得指甲技术、皮肤护理、美甲和美足、化妆艺术、身体护理和面部护理的技能",
        "涵盖美容院和水疗中心的商业管理技能",
        "包括基本计算机技能"
      ],
      assistance:["加拿大学生可能有资格获得财政援助",
        "可能的援助包括Better Jobs Ontario、社会援助和安大略省残疾支持计划（ODSP）",
        "可能有快速银行贷款",
        "学院提供延期付款计划",
        "OCOT每年提供20%至40%的奖学金"
      ],
      image: "/assets/images/mini_program_2.svg"
    },
    {
      title: "幼儿助理 (ECA) 早教",
      duration: "",
      hours: "总时数: 1025 小时",
      description: "在线课程+实习",
      classes:["为美容职业生涯做好准备",
        "为早期儿童助理（ECA）角色的儿童护理工作者提供培训",
        "受欢迎的项目",
        "容易就业",
        "高起薪",
        "工作稳定",
        "无需考试",
        "具有宝贵的ECA实践经验"
      ],
      assistance:["加拿大学生可能有资格获得财政援助",
        "可能的援助包括Better Jobs Ontario、社会援助和安大略省残疾支持计划（ODSP）",
        "可能有快速银行贷款",
        "学院提供延期付款计划",
        "OCOT每年提供20%至40%的奖学金"
      ],
      image: "/assets/images/mini_program_6.svg"
    },
    {
      title: "针灸",
      duration: "",
      hours: "总时数: 1900 小时",
      description: "实践与理论结合课程+实习",
      classes:["综合针灸文凭课程",
        "结合理论知识和实践技能",
        "根植于传统中医学",
        "准备学生成为注册针灸师",
        "毕业生有资格参加全加（PAN-CAN）考试"
      ],
      assistance:["加拿大学生可能有资格获得财政援助",
        "可能的援助包括Better Jobs Ontario、社会援助和安大略省残疾支持计划（ODSP）",
        "可能有快速银行贷款",
        "学院提供延期付款计划",
        "OCOT每年提供20%至40%的奖学金"
      ],
      image: "/assets/images/mini_program_7.svg"
    },
    {
      title: "质量管控",
      duration: "",
      hours: "总时数: 800 小时",
      description: "理论课+实践项目",
      classes:["质量专业人员必备的文凭",
        "涵盖该领域的基本知识和技能",
        "为ASQ认证（CQE、CQA、CSSBB等）做好准备"
      ],
      assistance:["加拿大学生可能有资格获得财政援助",
        "可能的援助包括Better Jobs Ontario、社会援助和安大略省残疾支持计划（ODSP）",
        "可能有快速银行贷款",
        "学院提供延期付款计划",
        "OCOT每年提供20%至40%的奖学金"
      ],
      image: "/assets/images/mini_program_1.svg"
    },
    {
      title: "商业管理",
      duration: "持续时间: 1年",
      hours: "总时数: 960 小时",
      description: "理论课+实践项目",
      image: "/assets/images/mini_program_4.svg"
    },
    {
      title: "国际商业管理（研究生文凭）",
      duration: "持续时间: 1年",
      hours: "总时数: 960 小时",
      description: "理论课+实践项目",
      image: "/assets/images/mini_program_5.svg"
    }
  ]
};

const MiniProgram = () => {
    const { language } = useContext(LanguageContext);  // 获取语言状态

    return (
        <div className={styles.miniProgramContainer}>
            <h2>{language === 'zh' ? '我们的其他项目' : 'Our Other Programs'}</h2>
            <div className={styles.programGrid}>
                {programs[language].map((program, index) => (
                    <div key={index} className={styles.programCard}>
                        <div className={styles.programImageContainer}>
                            <img src={program.image} alt={program.title} className={styles.programImage} />
                        </div>
                        <div className={styles.programContent}>
                            <h3>{program.title}</h3>
                            <p>{program.duration}</p>
                            <p>{program.hours}</p>
                            {program.details ? (
                                <div>
                                    {program.details.theory && <p>{program.details.theory}</p>}
                                    {program.details.practice && <p>{program.details.practice}</p>}
                                    {program.details.internship && <p>{program.details.internship}</p>}
                                </div>
                            ) : (
                              <div>
                                <h4>{program.description}</h4>
                                {program.classes && (
                                  <ul>
                                    {program.classes.map((classItem, classIndex) => (
                                      <li key={classIndex}>{classItem}</li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            )}
                            {program.assistance && (
                              <div>
                                <h4>{language === 'zh' ? '援助' : 'Assistance'}</h4>
                                <ul>
                                  {program.assistance.map((assistanceItem, index) => (
                                    <li key={index}>{assistanceItem}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MiniProgram;
