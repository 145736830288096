import styles from './Footer.module.scss';
import React, { useContext } from 'react';
import { LanguageContext } from '../Context/LanguageContext';

const Footer = () => {
    const { language } = useContext(LanguageContext);
    return (
        <footer className={styles.footer}>
            <div className={styles.contentWrapper}>
                <div className={styles.leftContent}>
                    <p><span className={styles.icon}>①</span> {language === 'zh' ? '更多详情，请访问官方的“Better Job Ontario”网站。' : 'For more details, please visit the official Better Job Ontario website.'}</p>
                    <p><span className={styles.icon}>②</span> {language === 'zh' ? '具体的补助金取决于个人情况。' : 'Specific grants depend on individual circumstances.'}</p>
                </div>
                <div className={styles.copyright}>
                    <p>2024 © Copyright. All Rights Reserved. Powered by TekoAI</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
