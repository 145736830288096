import React, { useContext, useEffect, useState } from 'react';
import Button from '../Button';
import styles from './Header.module.scss';
import { LanguageContext } from '../Context/LanguageContext';

const Header = () => {
    const logoSvg = "/assets/images/logo.svg";
    const { language, toggleLanguage } = useContext(LanguageContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // 锚点跳转到表单
    const scrollToForm = () => {
        const formSection = document.getElementById('form-section');
        if (formSection) {
            formSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // 检测屏幕宽度变化
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.header}>
            <div className={styles.headerFlex}>
                <a href="https://octech.ca/?page_id=2094&lang=zh">
                    <img src={logoSvg} alt="Logo" />
                </a>
            </div>
            
            <div className={styles.headerFlex}>
                <Button onClick={toggleLanguage}>
                   {language === 'en' ? '简体中文' : 'English'}
                </Button>

                {/* 仅在PC端展示立即预约按钮 */}
                {!isMobile && (
                    <Button onClick={scrollToForm} className={styles.bookNowButton}>
                        {language === 'en' ? 'Book Now!' : '立即预约！'}
                    </Button>
                )}

                <a href="tel:+4263328727">
                    <Button>
                        (416) 332-8727
                    </Button>
                </a>
            </div>
        </div>
    );
};

export default Header;
