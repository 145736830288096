import React, { useContext } from 'react';
import styles from './MainProgram.module.scss';
import { LanguageContext } from '../Context/LanguageContext';  // 引入LanguageContext

const MainProgram2 = () => {
  const { language } = useContext(LanguageContext);  // 获取当前语言状态

  return (
    <div className={styles.mainProgram}>
      <h2 className={styles.title}>{language === 'zh' ? '我们的主要项目 2' : 'Our Main Programs 2'}</h2>

      <div className={styles.contentWrapper}>
        <div className={styles.programSection}>
          <div className={styles.textSection}>
            <h3>{language === 'zh' ? '烹饪艺术专业' : 'Professional Culinary Arts'}</h3>
            <h4>800 {language === 'zh' ? '小时' : 'Hours'} /40 {language === 'zh' ? '周' : 'Weeks'}</h4>
            <p>{language === 'zh' ? '专业烹饪艺术课程涵盖从食品准备、烹饪、摆盘、呈现到服务的方方面面。如果您对烹饪充满激情，并希望将其转变为职业，那么该课程可以成为您激动人心的职业生涯的跳板。毕业生将为成为专业厨师做好准备。' : 'The Professional Culinary Arts program covers everything from preparation, cooking, plating, presentation, and serving food. If you are passionate about food and want to turn that into a profession, this program can be the springboard for an exciting career. Graduates are prepared for a career as a professional chef.'}</p>
            <p className={styles.approved}>{language === 'zh' ? '根据《安大略省职业学院法案，2005》批准的职业培训项目' : 'Approved as a vocational program under the Ontario Career Colleges Act, 2005'}</p>
          </div>
          <img src='/assets/images/main_program_1_1.svg' alt='Professional Culinary Arts' className={styles.programImage}/>
        </div>

        <div className={styles.programSection}>
          <img src='/assets/images/main_program_1_2.svg' alt='Career Opportunities' className={styles.programImage}/>
          <div className={styles.textSection}>
            <h3>{language === 'zh' ? '就业机会' : 'Career Opportunities'}</h3>
            <p>{language === 'zh' ? '实践与理论相结合的课程 + 实习：' : 'Practical & Theoretical hybrid classes + internship: '}</p>
            <ul>
              <li>{language === 'zh' ? '涵盖食品的准备、烹饪、摆盘、呈现和服务' : 'Covers preparation, cooking, plating, presentation, and serving of food'}</li>
              <li>{language === 'zh' ? '适合热爱美食的人' : 'Ideal for those passionate about food'}</li>
              <li>{language === 'zh' ? '为烹饪事业提供起点' : 'Serves as a springboard for a culinary career'}</li>
              <li>{language === 'zh' ? '为毕业生成为专业厨师做好准备' : 'Prepares graduates to become professional chefs'}</li>
            </ul>
          </div>
        </div>

        <div className={styles.programSection}>
          <div className={styles.textSection}>
            <h3>{language === 'zh' ? '财政援助' : 'Financial Assistance'}</h3>
            <ul>
              <li>{language === 'zh' ? '加拿大学生可能有资格获得财政援助' : 'Canadian students may be eligible for financial assistance'}</li>
              <li>{language === 'zh' ? '可能的援助包括安省更好工作计划（Better Jobs Ontario）、社会救助（Social Assistance）和安省残障支援计划（ODSP）' : 'Possible aid includes Better Jobs Ontario, Social Assistance, and The Ontario Disability Support Program (ODSP)'}</li>
              <li>{language === 'zh' ? '可能提供快速银行贷款' : 'Quick bank loans may be available'}</li>
              <li>{language === 'zh' ? '学院提供延期付款计划' : 'Deferred payment plans are offered by the college'}</li>
              <li>{language === 'zh' ? '安省技工学院（OCOT）每年提供20%到40%的奖学金' : 'OCOT offers 20% to 40% scholarships every year'}</li>
            </ul>
          </div>
          <img src='/assets/images/main_program_1_3.svg' alt='Financial Assistance' className={styles.programImage}/>
        </div>
      </div>
    </div>
  );
};

export default MainProgram2;
