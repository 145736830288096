import React, { useContext } from 'react';
import styles from './MainProgram.module.scss';
import { LanguageContext } from '../Context/LanguageContext';  // 引入LanguageContext

const MainProgram3 = () => {
  const { language } = useContext(LanguageContext);  // 获取当前语言状态

  return (
    <div className={styles.mainProgram}>
      <h2 className={styles.title}>{language === 'zh' ? '我们的主要项目 3' : 'Our Main Programs 3'}</h2>

      <div className={styles.contentWrapper}>
        <div className={styles.programSection}>
          <div className={styles.textSection}>
            <h3>{language === 'zh' ? '个人护理员' : 'Personal Support Worker (PSW)'}</h3>
            <h4>700 {language === 'zh' ? '小时' : 'Hours'} /28 {language === 'zh' ? '周' : 'Weeks'}</h4>
            <p>{language === 'zh' ? '通过该课程学习全面的医疗护理、日常护理技能，掌握医疗护理的技巧。' : 'Gain thorough preparation in healthcare and support services through this program.'}</p>
          </div>
          <img src='/assets/images/main_program_3_1.svg' alt='Personal Support Worker' className={styles.programImage}/>
        </div>

        <div className={styles.programSection}>
          <img src='/assets/images/main_program_3_2.svg' alt='Career Opportunities' className={styles.programImage}/>
          <div className={styles.textSection}>
          <h3>{language === 'zh' ? '就业机会' : 'Career Opportunities'}</h3>
            <ul>
              <li>{language === 'zh' ? '为学生准备个人支持工作者（PSW）角色：' : 'Prepares students for Personal Support Worker (PSW) roles'}</li>
              <li>{language === 'zh' ? '重点关注照顾老年人、残疾人士和需要帮助的个体' : 'Focus on caring for elderly, disabled, and individuals in need'}</li>
              <li>{language === 'zh' ? '提供动手实践培训和实际经验' : 'Hands-on training and practical experience'}</li>
              <li>{language === 'zh' ? '在医疗保健领域需求旺盛' : 'High demand in healthcare settings'}</li>
              <li>{language === 'zh' ? '提供即时就业机会' : 'Immediate employment opportunities'}</li>
            </ul>
          </div>
        </div>

        <div className={styles.programSection}>
        <div className={styles.textSection}>
            <h3>{language === 'zh' ? '财政援助' : 'Financial Assistance'}</h3>
            <ul>
              <li>{language === 'zh' ? '加拿大学生可能有资格获得财政援助' : 'Canadian students may be eligible for financial assistance'}</li>
              <li>{language === 'zh' ? '可能的援助包括安省更好工作计划（Better Jobs Ontario）、社会救助（Social Assistance）和安省残障支援计划（ODSP）' : 'Possible aid includes Better Jobs Ontario, Social Assistance, and The Ontario Disability Support Program (ODSP)'}</li>
              <li>{language === 'zh' ? '可能提供快速银行贷款' : 'Quick bank loans may be available'}</li>
              <li>{language === 'zh' ? '学院提供延期付款计划' : 'Deferred payment plans are offered by the college'}</li>
              <li>{language === 'zh' ? '安省技工学院（OCOT）每年提供20%到40%的奖学金' : 'OCOT offers 20% to 40% scholarships every year'}</li>
            </ul>
          </div>
          <img src='/assets/images/main_program_3_3.svg' alt='Second Career Funding' className={styles.programImage}/>
        </div>
      </div>
    </div>
  );
};

export default MainProgram3;
