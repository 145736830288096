import { useContext } from 'react';  // 引入 useContext
import { LanguageContext } from '../Context/LanguageContext';  // 引入LanguageContext
import styles from './Feature.module.scss';

const Feature = () => {
    const { language } = useContext(LanguageContext);  // 获取语言状态

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>
                {language === 'zh' ? '安省理工学院（OCOT）简介' : 'Ontario College of Technology (OCOT) Overview'}
            </h1>
            <p className={styles.text}>
                {language === 'zh'
                    ? '安省理工学院是一所在加拿大大多伦多地区享有盛誉的综合性理工学院。学院实力雄厚，设备先进，师资强大，信誉良好。学院提供大专文凭教育、职业教育、网上远程教育和公司现场培训等一系列课程项目。'
                    : 'The Ontario Institute of Technology is a prestigious comprehensive polytechnic institution located in the Greater Toronto Area of Canada. The institute boasts strong capabilities, advanced equipment, a powerful faculty, and a good reputation. It offers a range of programs including diploma education, vocational training, online distance education, and on-site corporate training.'
                }
            </p>
            <p className={styles.text}>
                {language === 'zh'
                    ? '安省理工学院是根据安省《职业学院法》2005年注册的“注册的职业学院”，安省理工学院同时在加拿大联邦政府人力资源部注册。'
                    : 'The Ontario Institute of Technology is registered as a "Private Career College" under the Ontario Private Career Colleges Act of 2005 and is also registered with the Canadian Federal Government\'s Human Resources Department.'
                }
            </p>
            <p className={styles.text}>
                {language === 'zh'
                    ? '安省理工学院是加拿大联邦政府公民和移民部（CIC）批准的招收国际学生的指定学习学院（Designated Learning Institutions）。'
                    : 'The Ontario Institute of Technology is a "Designated Learning Institution" approved by the Canadian Federal Government\'s Department of Citizenship and Immigration (CIC) to enroll international students.'
                }
            </p>
            <p className={styles.text}>
                {language === 'zh'
                    ? '国际学生可用安省理工学院录取的文件在世界上任何一个加拿大大使领馆申请学生签证（Study Permit）。'
                    : 'International students can use the admission documents from the Ontario Institute of Technology to apply for a student visa (Study Permit) at any Canadian embassy or consulate worldwide.'
                }
            </p>
            <p className={styles.motto}>
                {language === 'zh'
                    ? '安省理工学院的校训是：' 
                    : 'The institute\'s motto is:'
                } 
                <span className={styles.mottoHighlight}>
                    {language === 'zh' ? '止于至善' : '"To strive for excellence."'}
                </span>
            </p>
        </div>
    );
};

export default Feature;
