import styles from './Float.module.scss';
import Button from '../Button';
import { useContext } from 'react';
import { LanguageContext } from '../Context/LanguageContext';

const Float = () => {
    const { language } = useContext(LanguageContext); // 获取语言状态

    // 新增 scrollToForm 函数，滚动到表单锚点
    const scrollToForm = () => {
        const formSection = document.getElementById('form-section');
        if (formSection) {
            formSection.scrollIntoView({ behavior: 'smooth' }); // 平滑滚动到锚点位置
        }
    };

    return (
        <div className={styles.floatWrapper}>
            <div className={styles.float}>
                <div className={styles.floatButtons}>
                    <Button format="white"
                        onClick={() => window.open("https://app.tekoai.com/appointment?botId=247")}
                    >
                        {language === 'zh' ? '申请' : 'Apply'}
                    </Button>
                    {/* 使用 tel 链接拨打电话 */}
                    <Button format="white"
                        onClick={() => window.location.href = 'tel:+14163328727'}
                    >
                        (416) 332-8727
                    </Button>
                    <Button format="white" onClick={scrollToForm}>
                        {language === 'zh' ? '预约' : 'Book'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Float;
